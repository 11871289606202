import React, {useState, useEffect} from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import { FaStar } from "react-icons/fa";

import Review from "./Review";
import {BASE_URL} from "../config/constants";

function ServiceReviewList(props) {

	const [isLoading, setIsLoading] = useState(true);
	const [reviews, setReviews] = useState([]);

	const REPO_URL = "/api/provider/"+props.providerId+"/service-reviews";

	useEffect(() => {
		fetch(BASE_URL+REPO_URL, { method: "GET" })
			.then(res => res.json())
			.then(response => {
				console.log(response);
				let serviceList = [];
				response.data.forEach((service) => {
					serviceList.push({
						id: service.service_id,
						name: service.service.Registration_Group,
						rating: service.average,
					});
				});

				setIsLoading(false);
				setReviews(serviceList);
			})
			.catch(error => console.log(error));

	}, []);

	return (
		<React.Fragment>
			{isLoading && <p>Loading</p>}
			{reviews.length != 0 && (<div className="service-review-cont provider-rating-avg">
				{reviews.map(review => (
					<Row key={review.id} className="service-review">
						<Col>{review.name} </Col>
						<Col xs="auto"><FaStar size="20px" className="icons" color="#f7951f"/> {Number(review.rating).toFixed(2)}</Col>
					</Row>
				))}
			</div>)}
		</React.Fragment>
		);
}


export default ServiceReviewList;
import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { BASE_URL } from "../config/constants";

const useRequest = ({ url, method, headers, body, onSuccess, onError }) => {
	const [errors, setErrors] = useState('');
	const doRequest = async(props = {}) => {
		setErrors(null);
		try {
		console.log("token: "+ headers.commentToken);
			if (headers && headers.commentToken) {
				axios.defaults.headers.common['comment-token'] = headers.commentToken;
			}
			const response = await axios[method](BASE_URL+url, {...body, ...props});
			if (onSuccess) {
				onSuccess(response.data.data);
			}
			return response.data;
		} catch (err) {
			console.log(err);
			// if(err.response.data.data) {
			// 	setErrors(err.response.data.message)
			// }
			setErrors('Something went wrong');
			if (onError) {
				onError();
			}
		}
	}

	return { doRequest, errors };
}

export default useRequest;
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { FaStar } from "react-icons/fa";
import { useRouteMatch } from "react-router-dom"
// import localStorage from 'local-storage';

import Header from "../Header";
import ReviewList from "../ReviewList";
import ServiceReviewList from "./ServiceReviewList";
import ProviderDetail from "./ServiceDetail";
import ServiceRatingReviewForm from "./ServiceRatingReviewForm";
import ReviewModel from "../../model/ReviewModel";
import {BASE_URL} from "../../config/constants";
import { ApiClient } from "../../helpers/api-client";
import useRequest from "../../hooks/use-request";

function Service() {
	const [isReviewLoading, setIsReviewLoading] = useState(true);
	const [serviceName, setServiceName] = useState('');
	const [serviceRating, setServiceRating] = useState(null);
	const { params } = useRouteMatch();
	const [appName] = useState('AssistNow');
	const [reviews, setReviews] = useState([]);
	const [page, setPage] = useState(1);
	const [totalPage, setTotalPage] = useState(1);
	const [totalReviews, setTotalReviews] = useState(0);
	const token = params.token;
	const action = params.action;
	const loggedIn = params.userId !== undefined && token !== undefined;
	const loggedInReq = (action === 'share' && (!params.userId || !token));
	const [providerUserId, setProviderUserId] = useState(null);
	const [staleRating, setStaleRating] = useState(false);

	const onFetchServiceReviews = (payload) => {
		
		console.log(payload);
		const reviewList = [];
		payload.reviews.data.forEach((review) => {
			let liked = Array.isArray(review.liked_by_users) ? (review.liked_by_users.includes(parseInt(localStorage.getItem('userId'))) ? true : false) : false;
			let disliked = Array.isArray(review.disliked_by_users) ? (review.disliked_by_users.includes(parseInt(localStorage.getItem('userId'))) ? true : false) : false;

			reviewList.push(new ReviewModel(
				review.id,
				review.user_id,
				review.user ? review.user.name+" "+review.user.lastName : "Guest User",
				review.user ? review.user.profile_pic : '',
				review.rating,
				review.comment,
				Array.isArray(review.liked_by_users) ? review.liked_by_users.length: 0,
				Array.isArray(review.disliked_by_users) ? review.disliked_by_users.length: 0,
				review.count_comments.length > 0 ? review.count_comments[0].count : 0,
				review.created_at,
				liked,
				disliked
			));
		});

		setIsReviewLoading(false);
		setTotalPage(payload.reviews.last_page);
		setTotalReviews(payload.reviews.total);
		setReviews([...reviews, ...reviewList]);
	}

	const { doRequest, errors } = useRequest({
		url: "/api/service/"+params.serviceId+"/reviews?page="+page+"/",
		method: 'get',
		body: {},
		headers: {},
		onSuccess: onFetchServiceReviews
	});
	

	// const REPO_URL = "/api/existing-rating-provider-user/"+props.providerId;
	// const requestOptions = {
	// 		method: "GET",
	// 		headers: { 'Content-Type': 'application/json', 'comment-token': props.token  },
	// 	};

	// useEffect(() => {
	// 	fetch(BASE_URL+REPO_URL, requestOptions)
	// 		.then(res => res.json())
	// 		.then(response => {
	// 			console.log(response);
	// 			setRating(response.data.rating);
	// 			setComment(response.data.comment);
	// 		})
	// 		.catch(error => console.log(error));

	// }, []);

	useEffect(() => {
		if(loggedIn) {
			localStorage.setItem('token', token);
			localStorage.setItem('loggedIn', loggedIn);
			localStorage.setItem('userId', params.userId);
			localStorage.setItem('serviceId', params.serviceId);
		} else {
			localStorage.removeItem('token');
			localStorage.removeItem('loggedIn');
			localStorage.removeItem('userId');
			localStorage.removeItem('serviceId');
		}
	}, []);

	useEffect(() => {
		doRequest();
	}, [page]);

	const handleReviewAdd = (review) => {
		setReviews([review, ...reviews]);
		setStaleRating(true);
	}

	const handleReviewPagination = () => {
		console.log("Parent provider");
		setPage(page+1);
	}

	return (
		<>
			<Header appName={appName} loggedInBtnShow={loggedInReq && !loggedIn} serviceId={params.serviceId} token={params.token} />
			<div className="provider-wrap">
				<Container>
					<Row>
						<Col lg={4}>
							<ProviderDetail id={params.serviceId} token={token} staleRating={staleRating}/>
							<ServiceRatingReviewForm addReviewProps={handleReviewAdd} serviceId={params.serviceId} token={token} providerId={providerUserId} userId={params.userId} actionType={params.action} />
						</Col>
						<Col lg={8}>
							<ServiceReviewList serviceId={params.serviceId} reviews={reviews} page={page} totalPage={totalPage} totalReviews={totalReviews} handleReviewPaginateProps={handleReviewPagination}/>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
}

export default Service;

import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import localStorage from 'node-localstorage';

import Rating from "./ServiceRating";
import ReviewModel from "../../model/ReviewModel";
import {BASE_URL} from "../../config/constants";


function ServiceRatingReviewForm(props) {
	const [comment, setComment] = useState('');
	const [rating, setRating] = useState(null);
	const [hideForm, setHideForm] = useState(localStorage.getItem('role') == 'provider');


	// const REPO_URL = "/api/existing-rating-provider/"+props.serviceId;
	// const requestOptions = {
	// 		method: "GET",
	// 		headers: { 'Content-Type': 'application/json', 'comment-token': props.token  },
	// 	};

	// useEffect(() => {
	// 	fetch(BASE_URL+REPO_URL, requestOptions)
	// 		.then(res => res.json())
	// 		.then(response => {
	// 			console.log(response);
	// 			setRating(response.data.rating);
	// 			setComment(response.data.comment);
	// 		})
	// 		.catch(error => console.log(error));

	// }, []);

	const handleReviewFormSubmit = (e) => {
		e.preventDefault();
		// valid api token in header section
		// either rating or comment is required
		if(!props.token) {
			setRating(null);
			toast.error("You need to be logged in to post comment.");
			return;
		}
		if(comment.trim() || rating) {
			console.log('Valid to post');
		} else {
			setRating(null);
			toast.error('Please provider either feedback or rating.');
			return;
		}
		
		// pass the info to main container
		const requestOptions = {
			method: "POST",
			headers: { 'Content-Type': 'application/json', 'comment-token': props.token  },
			body: JSON.stringify({
				comment: comment,
				rating: rating,
				provider_id: localStorage.getItem('providerId') || 0,
				service_id: props.serviceId,
				user_id: props.userId
			})
		};
		
		fetch(BASE_URL+'/api/review', requestOptions)
			.then(response => response.json())
			.then(data => {
				console.log(data);
				const reviewData = data.data;
				if(Object.keys(reviewData).length === 0) {
					console.log(data.message);
					toast.error(data.message);
				} else {
					const review = new ReviewModel(
						reviewData.id,
						reviewData.user_id,
						reviewData.user.name+" "+reviewData.user.lastName,
						reviewData.user.profile_pic,
						reviewData.rating,
						reviewData.comment,
						reviewData.likes,
						reviewData.dislikes,
						0,
						reviewData.created_at
					);
					console.log('Inside success');
					toast.success("Rating/Review successfully posted.");
					props.addReviewProps(review);
					setHideForm(true);
				}

			})
			.catch(error => console.log(error));
		setComment('');
	}

	const handleRatingChange = (rating) => {
		setRating(rating);
	}

	return (
		<div className="give-rating">
			{!hideForm && (<Form onSubmit={handleReviewFormSubmit}>
				<h5 className="mb-3">Give stars and write a review for this provider:</h5>
				<Form.Group className="d-flex mb-3" controlId="formReviewRating">
					<Form.Label className="me-4">Rating</Form.Label>
					<Rating rating={rating} onReviewChangeProps={handleRatingChange} />
				</Form.Group>
				<Form.Group as={Row} controlId="formReviewComment">
					<Form.Control
						as="textarea"
						placeholder="Comment..."
						value={comment}
						name="comment"
						onChange={(event) => setComment(event.target.value)}>
					</Form.Control>
				</Form.Group>
				<Form.Group className="submit-btn text-end">
					<Button variant="success" type="submit">Submit</Button>
				</Form.Group>
			</Form>)}
		
		</div>
	);

}

export default ServiceRatingReviewForm;
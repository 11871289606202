import React, {useState, useRef, useEffect} from "react";

function ServiceRating(props) {

	const [currentRating, setCurrentRating] = useState(props.rating);
	const ratingEl = useRef(null);

	const numOfStars = 5;

	useEffect(() => {
		setCurrentRating(props.rating);
	}, [props.rating]);

	const setRating = (ev) => {
		const starElements = ratingEl.current.getElementsByClassName('star');
		Array.from(starElements).forEach(starEl => {
	      starEl.style.backgroundColor =
	        currentRating >= starEl.dataset.value ? '#f7951f' : '#d8d8d8';
	    });
	} 

	const handleStarClick = (ev) => {
		let rating = ev.target.dataset.value;
		setCurrentRating(rating);
		props.onReviewChangeProps(rating);
	}

	const handleStarHover = (ev) => {
		const hoverValue = ev.target.dataset.value;
		const starElements = ev.target.parentElement.getElementsByClassName('star');
		Array.from(starElements).forEach(starEl => {
			starEl.style.backgroundColor = hoverValue >= starEl.dataset.value ? '#f7951f' : '#d8d8d8';
		});
	}

	return (
		<div className="star-wrap" ref={ratingEl} data-rating={currentRating} onMouseOut={setRating}>
			{[...Array(+numOfStars).keys()].map(n => {
				return (
					<span
						className="star"
						key={n+1}
						data-value={n+1}
						onClick={handleStarClick}
						onMouseOver={handleStarHover}
						style={n < currentRating ? {backgroundColor: '#f7951f'} : {backgroundColor: '#d8d8d8'}}
					>
					&#9733;
					</span>);
			})}
		</div>
	);
}

export default ServiceRating;
import React, {useState, useEffect} from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
// import localStorage from 'local-storage';
import { toast } from 'react-toastify';

import {BASE_URL} from "../../config/constants";
import ReplyModel from "../../model/ReplyModel";
import ServiceReply from "./ServiceReply";

function ServiceReplyList(props) {
	
	const [threadId] = useState(props.threadId);
	const [replies, setReplies] = useState([]);
	const [replyText, setReplyText] = useState('');
	const [showReplyList, setShowReplyList] = useState(props.showReplies);

	const REPO_URL = "/api/review/"+threadId+"/comments";


	const handleReplySubmit = (e) => {
		e.preventDefault();
		// valid api token in header section
		// either rating or comment is required
		const token = localStorage.getItem('token') || null;
		if(!token) {
			toast.error("You need to be logged in to post comment.");
			return;
		}
		
		// pass the info to main container
		const requestOptions = {
			method: "POST",
			headers: { 'Content-Type': 'application/json', 'comment-token': token  },
			body: JSON.stringify({
				comment: replyText,
				provider_id: localStorage.getItem('providerId') || 0,
				service_id: localStorage.getItem('serviceId') || 0,
				user_id: localStorage.getItem('userId') || null,
				main_thread_id: threadId
			})
		};
		
		fetch(BASE_URL+'/api/review', requestOptions)
			.then(response => response.json())
			.then(data => {
				console.log(data);
				const reviewData = data.data;
				if(Object.keys(reviewData).length === 0) {
					console.log(data.message);
					toast.error(data.message);
				} else {
					const reply = new ReplyModel(
						reviewData.id,
						reviewData.user_id,
						reviewData.user.name+" "+reviewData.user.lastName,
						reviewData.user ? reviewData.user.profile_pic : '',
						reviewData.comment,
						reviewData.likes,
						reviewData.dislikes,
						reviewData.created_at
					);
					setReplies([...replies, reply]);
					toast.success("Reply successfully posted.");
					setShowReplyList(false);
					setReplies([]);
					props.handleReplySuccessProps();
				}

			})
			.catch(error => console.log(error));
		setReplyText('');
	}

	useEffect(() => {

		const requestOptions = {
			method: "GET",
			headers: { 'Content-Type': 'application/json' },
		};

		fetch(BASE_URL+REPO_URL, requestOptions)
			.then(res => res.json())
			.then(response => {
				console.log(response);
				const repliesData = response.data.reviews;
				const repliesList = [];
				repliesData.forEach((reply) => {
					let liked = Array.isArray(reply.liked_by_users) ? (reply.liked_by_users.includes(parseInt(localStorage.getItem('userId'))) ? true : false) : false;
					let disliked = Array.isArray(reply.disliked_by_users) ? (reply.disliked_by_users.includes(parseInt(localStorage.getItem('userId'))) ? true : false) : false;
					console.log(reply);
					repliesList.push(new ReplyModel(
						reply.id,
						reply.user_id,
						reply.user ? reply.user.name+" "+reply.user.lastName : "Guest User",
						reply.user ? reply.user.profile_pic : '',
						reply.comment,
						Array.isArray(reply.liked_by_users) ? reply.liked_by_users.length: 0,
						Array.isArray(reply.disliked_by_users) ? reply.disliked_by_users.length: 0,
						reply.created_at,
						reply.comment_origin,
						liked,
						disliked
					));
				});
				setReplies(repliesList);
			})
			.catch(error => console.log(error));
	}, [props.threadId]);

	return (
		<React.Fragment>
			{replies && replies.length > 0 && replies.map(reply => (
				<ServiceReply key={reply.id} replyProp={reply} />
			))}
			{showReplyList && (<Form onSubmit={handleReplySubmit}>
				<div className="reply-field text-end">
					<Form.Control
						as="textarea"
						value={replyText}
						name="comment"
						onChange={(event) => setReplyText(event.target.value)}
						required="required"
						className="replyTextBox">
					</Form.Control>
					<Button variant="success" type="submit">Submit</Button>
				</div>
			</Form>)}
		</React.Fragment>
	);
}
export default ServiceReplyList;
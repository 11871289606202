import React, { useState, useEffect } from "react";
import { Card, Spinner, Row, Col} from "react-bootstrap";
import { FaStar, FaPaperPlane, FaPhoneSquare, FaEnvelope, FaTags } from "react-icons/fa";
import { toast } from 'react-toastify';

import {BASE_URL} from "../../config/constants";
import ServiceModel from "../../model/ServiceModel";
import useRequest from "../../hooks/use-request";

function ServiceDetail(props) {
	const [isLoading, setIsLoading] = useState(true);
	const [service, setService] = useState({});
	const [currentRating, setCurrentRating] = useState(props.staleRating);
	
	const numOfStars = 5;
	const serviceStars = Number(service.rating).toFixed(2);

	const onFetchServiceDetail = (serviceData) => {
		let service = new ServiceModel(
			serviceData.id,
			serviceData.name,
			serviceData.provider_name,
			serviceData.address,
			serviceData.phone,
			serviceData.email,
			serviceData.provider_user_id,
			serviceData.rating,
			serviceData.review_count
		);
		setIsLoading(false);
		setService(service);
		console.log('I will set current rating', currentRating);
		setCurrentRating(true);
		console.log('I already set current rating', currentRating);
	}

	const onErrorFetchingService = () => {
		setIsLoading(false);
		toast.error("Something went wrong");
	}

	const { doRequest, errors } = useRequest({
		url: `/api/service-rating/${props.id}`,
		method: 'get',
		body: {},
		headers: {'commentToken': props.token, 'ContentType': 'application/json'},
		onSuccess: onFetchServiceDetail,
		onError: onErrorFetchingService
	});

	useEffect(() => {
		console.log('Called', currentRating);
		doRequest();
	}, [props.staleRating]);

	return (
		<React.Fragment>
			{isLoading && 
				<Spinner animation="border" role="status" className="spinner">
					<span className="sr-only"></span>
				</Spinner>}
			{!isLoading && <div className="provider-info">
				<h3 className="provider-name mb-1">{service.name}</h3>
				<h6 className="provider-category"><FaTags size="19px" className="icons" color="black" /> {service.provider_name}</h6>

				<div className="provider-rating d-flex align-items-baseline mt-3">
						<span className="pe-3">Rating</span>
						<span className="rating-count pe-2">{Number(service.rating).toFixed(2)}</span>

					<div className="star-wrap pe-2">
							{[...Array(+numOfStars).keys()].map(n => {
								return (
									 
									<span
										className="star"
										key={n + 1}
										data-value={n + 1}
										style={n < serviceStars ? { backgroundColor: '#f7951f' } : { backgroundColor: '#d8d8d8' }}
									>
										&#9733;
									</span>);
							})}
						</div>
						
						{/* <FaStar size="20px" className="icons" color="green" /> */}
						({service.review_count} Reviews)
					
				</div>
				<div className="mt-4">
					<ul className="list-unstyled provider-contact-info">
					<li><label className="detail-label"><FaPhoneSquare size="22px" className="icons" color="black" /></label> {service.phone}</li>
						<li><label className="detail-label"><FaPaperPlane size="18px" className="icons" color="black" /></label>{service.address}</li>
						<li><label className="detail-label"><FaEnvelope size="18px" className="icons" color="black" /></label>{service.email}</li>
					</ul>
				</div>
			</div>}
		</React.Fragment>
		); 
}

export default ServiceDetail;
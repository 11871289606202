export default class ReplyModel {
	constructor(id, user_id, user_fullname, user_profilepic, comment, likes, dislikes, created_at, comment_origin, liked=false, disliked=false) {
		this.id = id;
		this.user_id = user_id;
		this.user_fullname = user_fullname;
		this.profile_pic = user_profilepic;
		this.comment = comment;
		this.likes = likes;
		this.dislikes = dislikes;
		this.created_at = created_at;
		this.comment_origin = comment_origin;
		this.liked = liked;
		this.disliked = disliked;
	}
}
import React, { useState, useEffect } from "react";
import { Navbar, Container} from "react-bootstrap";
// import localStorage from 'local-storage';
// import { LocalStorage } from "node-localstorage";

import useRequest from "../hooks/use-request";

function Header(props) {
	const userId = localStorage.getItem('userId') || 0;
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const logoutUser = () => {
		console.log('logging out user');
		localStorage.removeItem('token');
		localStorage.removeItem('user_id');
		localStorage.removeItem('role');
		localStorage.removeItem('logged_in');
		setIsLoggedIn(false);
	}

	const loginUser = (user) => {
		console.log('logging in...');
		localStorage.setItem('token', props.token);
		localStorage.setItem('user_id', user.id);
		localStorage.setItem('role', user.role);
		localStorage.setItem('logged_in', true);
		setIsLoggedIn(true);
	}
	console.log("token from page: " +props.token);
	const { doRequest, errors } = useRequest({
		url: '/api/reviews/current-user',
		method: 'get',
		body: {  },
		headers: {'commentToken': props.token, 'ContentType': 'application/json'},
		onSuccess: loginUser,
		onError: logoutUser
	});

	useEffect(() => {
		doRequest();
	}, []);

	return(
		<React.Fragment>
			<div className="header-wrap">
				<Container>
					<Navbar bg="white">
						<Navbar.Brand>
							<img
								alt=""
								src="https://assistnow.com.au/assets/images/logo.png"
								width="auto"
								height="30"
								className="d-inline-block align-top"
								/></Navbar.Brand>
						{!isLoggedIn && <a href={"https://staging.assistnow.com.au/Reviews-Comments/" + props.serviceId + "/" + userId} target="_blank" rel="noreferrer" className="btn btn btn-outline-dark ms-auto">Login</a>}
						
					</Navbar>
				</Container>
			</div>
		</React.Fragment>
	);
}

export default Header;
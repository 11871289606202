import React, {useState, useEffect} from "react";
import { Row, Col, Container } from "react-bootstrap";
import { FaRegClock } from "react-icons/fa";
import { AiOutlineLike, AiOutlineDislike } from "react-icons/ai";
import { IoChatbubbleOutline } from "react-icons/io5";
// import localStorage from 'local-storage';
import { toast } from 'react-toastify';

import {BASE_URL} from "../../config/constants";
import ServiceReplyList from "./ServiceReplyList";
import timeElpased from '../../helpers/helpers';

function ServiceReview(props) {
	const [review] = useState(props.review);
	const [replyCount, setReplyCount] = useState(props.review.reply_count);
	const [showReplies, setShowReplies] = useState(false);
	const [likes, setLikes] = useState(props.review.likes);
	const [dislikes, setDislikes] = useState(props.review.dislikes);
	const [liked, setLiked] = useState(props.liked);
	const [disliked, setDisliked] = useState(props.disliked);

	const handleReplySuccess = () => {
		console.log('parent method called');
		setReplyCount(replyCount+1);
	}

	const handleLikeToggle = () => {
		if(!localStorage.getItem('loggedIn')) {
			toast.error("You need to be logged-in to like/dislike comment.");
			return;
		}

		const requestOptions = {
			method: "POST",
			headers: { 'Content-Type': 'application/json', 'comment-token': localStorage.getItem('token') || null },
			body: JSON.stringify({
				type: 'like',
				thread_id: review.id,
				action: liked ? 'remove' : 'add',
				user_id: localStorage.getItem('userId') || null
			})
		};


		fetch(BASE_URL+'/api/review-like-dislike', requestOptions)
			.then(response => response.json())
			.then(data => {
				console.log(data);
				setLikes(Array.isArray(data.data.liked_by_users) ? data.data.liked_by_users.length: 0);
				setDislikes(Array.isArray(data.data.disliked_by_users) ? data.data.disliked_by_users.length: 0);
				setLiked(!liked)
				if(disliked) {
					setDisliked(false);
				}
			})
			.catch(error => console.log(error));
	};

	const handleDisLikeToggle = () => {
		if(!localStorage.getItem('loggedIn')) {
			toast.error("You need to be logged-in to like/dislike comment.");
			return;
		}
		const requestOptions = {
			method: "POST",
			headers: { 'Content-Type': 'application/json', 'comment-token': localStorage.getItem('token') || null  },
			body: JSON.stringify({
				type: 'dislike',
				thread_id: review.id,
				action: disliked ? 'remove' : 'add',
				user_id: localStorage.getItem('userId') || null
			})
		};
		fetch(BASE_URL+'/api/review-like-dislike', requestOptions)
			.then(response => response.json())
			.then(data => {
				console.log(data);
				setLikes(Array.isArray(data.data.liked_by_users) ? data.data.liked_by_users.length: 0);
				setDislikes(Array.isArray(data.data.disliked_by_users) ? data.data.disliked_by_users.length: 0);
				setDisliked(!disliked)
				if(liked) {
					setLiked(false);
				}
			})
			.catch(error => console.log(error));
	};

	const loadReplies = () => {
		setShowReplies(!showReplies);
	}

  const imageOnError = (event) => {
    event.currentTarget.src = '/default-user.png';
    event.currentTarget.style.border = '0px';
  };
	return (
		<React.Fragment>
		<div className="user-review-item">
			<Row className="justify-content-between">
				<Col xs="auto"><img src={ (review  && review.profile_pic !== '') ? BASE_URL+'/profile_pic/'+review.profile_pic : '/default-user.png' } alt="user" onError={imageOnError} className="user-image"/></Col>
				<Col xs="auto" className="order-sm-3">
					<div className="review-action mt-3 mt-sm-0">
						<span className="review-like" onClick={handleLikeToggle}><AiOutlineLike color={liked ? 'green' : '#95c950'} size="24px" className="" />
							<span>{likes}</span>
						</span>
						<span onClick={handleDisLikeToggle} className="review-dislike"><AiOutlineDislike color={disliked ? 'red' : '#ff1919'} size="23px" className="" />
							<span>{dislikes}</span>
						</span>
						<span onClick={loadReplies} className="review-coment"><IoChatbubbleOutline color={review.replies > 0 ? 'green' : '#191919'} size="21px" className="" />
							<span>{replyCount}</span>
						</span>
					</div>
				</Col>
				<Col className="mt-3 mt-sm-0">
					<h5>{review.user_fullname}</h5>
					<p>{review.comment}</p>
					<span className="user-review-time"><FaRegClock className="icons"/>{timeElpased(review.created_at)}</span>
				</Col>
			</Row>
			{showReplies && <div className="reply-wrap">
				<ServiceReplyList threadId={review.id} showReplies={showReplies} handleReplySuccessProps={handleReplySuccess}/>
			</div>}
		</div>
		</React.Fragment>
	);
}
export default ServiceReview;
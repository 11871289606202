import React from "react";
import { Container } from "react-bootstrap";


import Header from "./Header";

function NoMatch() {
	return(
		<Container>
			<Header />
			<h1>Invalid URL</h1>
		</Container>
	);
}

export default NoMatch;
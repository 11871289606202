import React from 'react';
import { Route, Switch } from "react-router-dom"
import 'react-toastify/dist/ReactToastify.css';

import Provider from './Provider';
import Service from './Service/Service';
import ProviderList from './ProviderList';
import ServiceList from './Service/ServiceList';
import NoMatch from './NoMatch';

function MainApp() {
	return (
		<Switch>
			<Route exact path="/providers/:providerId/:action?/:userId?/:token?">
				<Provider />
			</Route>
			<Route exact path="/services/:serviceId/:action?/:userId?/:token?">
				<Service />
			</Route>
			<Route exact path="/services">
				<ServiceList />
			</Route>
			<Route path="/providers">
				<ProviderList />
			</Route>
			<Route exact path="/">
				<ServiceList />
			</Route>
			<Route path="*">
				<NoMatch />
			</Route>
		</Switch>
	);
}

export default MainApp;
import React from "react";
import { Container, Button } from "react-bootstrap";

import Review from "./Review";

function ReviewList(props) {

	const handleReviewChange = () => {
		console.log('Review has been changed.');
	};

	const handleReviewPaginate = () => {
		props.handleReviewPaginateProps();
	}

	return (
		<React.Fragment>
			
			<div className="user-review-section ms-lg-4 ms-lg-0 mt-4">
				<h2>Review by 6 users</h2>
				{props.reviews.map(review => (
					<Review
						key={review.id}
						review={review}
						liked={review.liked}
						disliked={review.disliked}
						onReviewChangeProps={handleReviewChange}
					/>
				))}
				{props.page < props.totalPage && <Button variant="secondary" className="submit-btn load-more-btn" onClick={handleReviewPaginate}>Load More...</Button>}
			</div>
			
		</React.Fragment>
		);
}


export default ReviewList;
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useRouteMatch } from "react-router-dom"
// import localStorage from 'local-storage';

import Header from "./Header";
import ReviewList from "./ReviewList";
import ServiceReviewList from "./ServiceReviewList";
import ProviderDetail from "./ProviderDetail";
import RatingReviewForm from "./RatingReviewForm";
import ReviewModel from "../model/ReviewModel";
import {BASE_URL} from "../config/constants";

function Provider() {
	const [isReviewLoading, setIsReviewLoading] = useState(true);
	const { params } = useRouteMatch();
	const [appName] = useState('AssistNow');
	const [reviews, setReviews] = useState([]);
	const [page, setPage] = useState(1);
	const [totalPage, setTotalPage] = useState(1);
	const token = params.token;
	const action = params.action;
	const loggedIn = params.userId !== undefined && token !== undefined;
	const loggedInReq = (action === 'invite' && (!params.userId || !token));

	console.log(!token, params.userId, loggedIn, loggedInReq);


	const REPO_URL = "/api/provider/"+params.providerId+"/reviews";
	console.log(params);
	useEffect(() => {
		if(loggedIn) {
			localStorage.setItem('token', token);
			localStorage.setItem('loggedIn', loggedIn);
			localStorage.setItem('userId', params.userId);
			localStorage.setItem('providerId', params.providerId);
		} else {
			localStorage.removeItem('token');
			localStorage.removeItem('loggedIn');
			localStorage.removeItem('userId');
			localStorage.removeItem('providerId');
		}
	}, []);

	useEffect(() => {
		fetch(BASE_URL+REPO_URL+"?page="+page, { method: "GET" })
			.then(res => res.json())
			.then(response => {
				console.log(response);
				const respnseData = response.data;
				const reviewList = [];
				respnseData.reviews.data.forEach((review) => {
					console.log(review);
					let liked = Array.isArray(review.liked_by_users) ? (review.liked_by_users.includes(parseInt(localStorage.getItem('userId'))) ? true : false) : false;
					let disliked = Array.isArray(review.disliked_by_users) ? (review.disliked_by_users.includes(parseInt(localStorage.getItem('userId'))) ? true : false) : false;
					reviewList.push(new ReviewModel(
						review.id,
						review.user_id,
						review.user ? review.user.name+" "+review.user.lastName : "Deleted User",
						review.user ? review.user.prfile_pic : '',
						review.rating,
						review.comment,
						Array.isArray(review.liked_by_users) ? review.liked_by_users.length: 0,
						Array.isArray(review.disliked_by_users) ? review.disliked_by_users.length: 0,
						review.count_comments.length > 0 ? review.count_comments[0].count : 0,
						review.created_at,
						liked,
						disliked
					));
				});
				setIsReviewLoading(false);
				setTotalPage(respnseData.reviews.last_page);
				setReviews([...reviewList, ...reviews]);
			})
			.catch(error => console.log(error));

	}, [page]);

	const handleReviewAdd = (review) => {
		setReviews([review, ...reviews]);
	}

	const handleReviewPagination = () => {
		console.log("Parent provider");
		setPage(page+1);
	}

	return (
		<>
			<Header appName={appName} loggedInBtnShow={loggedInReq && !loggedIn} />
			<div className="provider-wrap">
				<Container>
					<Row>
						<Col lg={4}>
							<ProviderDetail id={params.providerId} token={token} />
							<ServiceReviewList providerId={params.providerId} />
							<RatingReviewForm addReviewProps={handleReviewAdd} providerId={params.providerId} token={token} userId={params.userId} actionType={params.action} />
						</Col>
						<Col lg={8}>
							<ReviewList providerId={params.id} reviews={reviews} page={page} totalPage={totalPage} handleReviewPaginateProps={handleReviewPagination} />
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
}

export default Provider;

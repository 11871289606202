import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom"
import 'bootstrap/dist/css/bootstrap.min.css';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import MainApp from "./components/MainApp";
import './App.css';

TimeAgo.addDefaultLocale(en)


ReactDOM.render(
	<React.StrictMode>
		<HashRouter>
			<ToastContainer />
			<MainApp />
		</HashRouter>
	</React.StrictMode>,
	document.getElementById("root"));
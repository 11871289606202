import React from "react";
import { Container, Button } from "react-bootstrap";

import ServiceReview from "./ServiceReview";

function ServiceReviewList(props) {

	// console.log(props.reviews);

	const handleReviewChange = () => {
		console.log('Review has been changed.');
	};

	const handleReviewPaginate = () => {
		props.handleReviewPaginateProps();
	}

	return (
		<React.Fragment>
			<div className="user-review-section ms-lg-4 ms-lg-0 mt-4">
				<h2>Review by {props.totalReviews > 0 ? props.totalReviews + " users" : "users" }</h2>
				{props.reviews.length === 0 ? (<p className="m-3">No reviews yet.</p>) : ''}
				{props.reviews.map(review => (
					<ServiceReview
						key={review.id}
						review={review}
						liked={review.liked}
						disliked={review.disliked}
						onReviewChangeProps={handleReviewChange}
					/>
				))}
				{props.page < props.totalPage && <Button variant="secondary" className="submit-btn load-more-btn" onClick={handleReviewPaginate}>Load More...</Button>}
			</div>
		</React.Fragment>
		);
}


export default ServiceReviewList;
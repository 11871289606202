import {BASE_URL} from "../config/constants";

export class ApiClient {
	static statusCode = 'Not okay';
	static GET =  async (url, options, successCallback, failureCallback = null) => {
		fetch(BASE_URL+url)
			.then(response => { console.log(response) });

		successCallback('data');
	}
}
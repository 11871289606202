import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom"

import Header from "./Header";
import {BASE_URL} from "../config/constants";

function ProviderList() {
	const [isLoading, setIsLoading] = useState(true);
	const [providers, setProviders] = useState([]);
	const [appName] = useState('AssistNow')

	const REPO_URL = "/api/providers-list-ratings";

	useEffect(() => {
		fetch(BASE_URL+REPO_URL, { method: "GET" })
			.then(res => res.json())
			.then(response => {
				console.log(response);
				const respnseData = response.data;
				const providerList = [];
				respnseData.forEach((provider) => {
					providerList.push({
						id: provider.id,
						name: provider.name+" "+provider.lastName,
						email: provider.email,
						mobile: provider.mobile
					});
				});
				setIsLoading(false);
				setProviders(providerList);
			})
			.catch(error => console.log(error));

	}, []);

	return (
		<Container>
			<Header appName={appName} />
			{isLoading && <p>Loading...</p>}
			<Container className="provider-cont">
				{providers.map((provider, index) => (
				<Link to={"providers/"+provider.id+"/invite"} key={provider.id}>
					<Row className="provider-row" key={provider.id}>
						<Col>{provider.name}</Col>
						<Col>{provider.email}</Col>
						<Col>{provider.mobile}</Col>
					</Row>
				</Link>
				))}
			</Container>
		</Container>
		); 
}

export default ProviderList;
import React, {useState} from "react";
import { Row, Col } from "react-bootstrap";
import { FaRegClock } from "react-icons/fa";
import { AiOutlineLike, AiOutlineDislike, AiOutlineCheckCircle } from "react-icons/ai";
// import localStorage from 'local-storage';
import { toast } from 'react-toastify';

import {BASE_URL} from "../../config/constants";
import timeElpased from '../../helpers/helpers';

function ServiceReply(props) {
	const [reply] = useState(props.replyProp);
	const [likes, setLikes] = useState(props.replyProp.likes);
	const [dislikes, setDislikes] = useState(props.replyProp.dislikes);
	const [liked, setLiked] = useState(props.replyProp.liked);
	const [disliked, setDisliked] = useState(props.replyProp.disliked);

	const handleLikeToggle = () => {
		if(!localStorage.get('loggedIn')) {
			toast.error("You need to be logged-in to like/dislike comment.");
			return;
		}

		const requestOptions = {
			method: "POST",
			headers: { 'Content-Type': 'application/json', 'comment-token': localStorage.get('token') || null },
			body: JSON.stringify({
				type: 'like',
				thread_id: reply.id,
				action: liked ? 'remove' : 'add',
				user_id: localStorage.get('userId') || null
			})
		};


		fetch(BASE_URL+'/api/review-like-dislike', requestOptions)
			.then(response => response.json())
			.then(data => {
				console.log(data);
				setLikes(Array.isArray(data.data.liked_by_users) ? data.data.liked_by_users.length: 0);
				setDislikes(Array.isArray(data.data.disliked_by_users) ? data.data.disliked_by_users.length: 0);
				setLiked(!liked)
				if(disliked) {
					setDisliked(false);
				}
			}).catch(error => console.log(error));
	};

	const handleDisLikeToggle = () => {
		if(!localStorage.get('loggedIn')) {
			toast.error("You need to be logged-in to like/dislike comment.");
			return;
		}
		const requestOptions = {
			method: "POST",
			headers: { 'Content-Type': 'application/json', 'comment-token': localStorage.get('token') || null  },
			body: JSON.stringify({
				type: 'dislike',
				thread_id: reply.id,
				action: disliked ? 'remove' : 'add',
				user_id: localStorage.get('userId') || null
			})
		};
		fetch(BASE_URL+'/api/review-like-dislike', requestOptions)
			.then(response => response.json())
			.then(data => {
				console.log(data);
				setLikes(Array.isArray(data.data.liked_by_users) ? data.data.liked_by_users.length: 0);
				setDislikes(Array.isArray(data.data.disliked_by_users) ? data.data.disliked_by_users.length: 0);
				setDisliked(!disliked)
				if(liked) {
					setLiked(false);
				}
			})
			.catch(error => console.log(error));
	};


	return (
		<div className="reply-row">
			<Row className="justify-content-between">
				<Col xs="auto"><img src={ (reply.profile_pic && reply.profile_pic !== '') ? BASE_URL+'/profile_pic/'+reply.profile_pic : '/default-user.png' } alt="user" className="user-image"/></Col>
				<Col xs="auto" className="order-sm-3">
					<div className="review-action">
						<span className="review-like" onClick={handleLikeToggle}>
							<AiOutlineLike color={liked ? 'green' : '#95c950'} size="20px" className="icons" />
							<span>{likes}</span>
						</span>
						<span className="review-dislike" onClick={handleDisLikeToggle}>
							<AiOutlineLike color={disliked ? 'red' : '#ff1919'} size="20px" className="icons" />
							<span>{dislikes}</span>
						</span>
					</div>
				</Col>
				<Col className="mt-3 mt-sm-0">
					<h6>{reply.user_fullname} {reply.comment_origin && reply.comment_origin.toLowerCase() === 'provider' &&
						<AiOutlineCheckCircle title="Verified Provider" color="#8ac33d" className="icons" />}</h6>
					<p>{reply.comment}</p>
					
					<span className="user-review-time"><FaRegClock className="icons" />{timeElpased(reply.created_at)}</span>
				</Col>
			</Row>
			
		</div>
	);
}
export default ServiceReply;
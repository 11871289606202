export default class ServiceModel {
	constructor(id, name, provider_name, address, phone, email, provider_user_id, rating = 0, review_count = 0) {
		this.id = id;
		this.name = name;
		this.provider_name = provider_name;
		this.address = address;
		this.email = email;
		this.phone = phone;
		this.provider_user_id = provider_user_id;
		this.rating = rating;
		this.review_count = review_count;
	}
}
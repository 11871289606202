import React, { useState, useEffect } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom"

import Header from "../Header";
import {BASE_URL} from "../../config/constants";
import useRequest from "../../hooks/use-request";

function ServiceList() {
	const [isLoading, setIsLoading] = useState(true);
	const [services, setServices] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState(1);

	const [appName] = useState('AssistNow')

	const handleServicePaginate = () => {
		setCurrentPage(currentPage+1);
	}

	const onFetchServices = (payload) => {
		const serviceList = [];
		payload.data.forEach((service) => {
			serviceList.push({
				id: service.id,
				provider_name: service.Registration_Group,
				provider_phone: service.PrvdrPhnNmbr,
				service_name: service.Registered_Provider_Name,
				email: service.Email_Address
			});
		});
		setIsLoading(false);
		setTotalPage(payload.last_page);
		setServices([...serviceList, ...services]);
	}

	const { doRequest, errors } = useRequest({
		url: '/api/services-list-ratings/?page=' + currentPage,
		method: 'get',
		body: {},
		headers: {},
		onSuccess: onFetchServices
	});

	useEffect(() => {
		doRequest();
	}, [currentPage]);

	return (
		<Container>
			<Header appName={appName} />
			{isLoading && <p>Loading...</p>}
			{currentPage < totalPage && <Button variant="secondary" className="submit-btn" onClick={handleServicePaginate}>Load More...</Button>}
			<Container className="provider-cont">
				{services.map((service, index) => (
				<Link to={"services/"+service.id+"/invite"} key={service.id}>
					<Row className="provider-row" key={service.id}>
						<Col>{service.provider_name}</Col>
						<Col>{service.service_name}</Col>
						<Col>{service.email}</Col>
					</Row>
				</Link>
				))}
			</Container>
		</Container>
		); 
}

export default ServiceList;
import React, { useState, useRef, useEffect } from "react";
import { Spinner, Row, Col} from "react-bootstrap";
import { FaStar, FaPaperPlane, FaPhoneSquare, FaEnvelope, FaTags } from "react-icons/fa";

import {BASE_URL} from "../config/constants";

function ProviderDetail(props) {
	const [isLoading, setIsLoading] = useState(true);
	const [provider, setProvider] = useState({});

	const REPO_URL = "/api/provider-rating/"+props.id;
	const requestOptions = {
			method: "GET",
			headers: { 'Content-Type': 'application/json', 'comment-token': props.token  },
		};
	useEffect(() => {
		fetch(BASE_URL+REPO_URL, requestOptions)
			.then(res => res.json())
			.then(response => {
				console.log(response);
				let provider = {
					id: response.data.id,
					name: response.data.name,
					email: response.data.email,
					address: response.data.address,
					mobile: response.data.mobile,
					rating: response.data.rating,
					reviews: response.data.review_count
				};
				setIsLoading(false);
				setProvider(provider);
			})
			.catch(error => console.log(error));

	}, []);

	const numOfStars = 5;
	const providerStars = Number(provider.rating).toFixed(2);



	return (
		<React.Fragment>
			{isLoading && 
				<Spinner animation="border" role="status" className="spinner">
					<span className="sr-only"></span>
				</Spinner>}
			{!isLoading && <div className="provider-info">
				
				<h3 className="provider-name mb-1">{provider.name}</h3>
				<h6 className="provider-category"><FaTags size="19px" className="icons" color="black" /> Health care</h6>
				<div className="provider-rating d-flex align-items-baseline mt-3">
						<span className="pe-3">Rating</span>
						<span className="rating-count pe-2">{Number(provider.rating).toFixed(2)}</span>

					<div className="star-wrap pe-2">
							{[...Array(+numOfStars).keys()].map(n => {
								return (
									 
									<span
										className="star"
										key={n + 1}
										data-value={n + 1}
										style={n < providerStars ? { backgroundColor: '#f7951f' } : { backgroundColor: '#d8d8d8' }}
									>
										&#9733;
									</span>);
							})}
						</div>
						
						{/* <FaStar size="20px" className="icons" color="green" /> */}
						({provider.reviews} Reviews)
					
				</div>
					<div className="mt-4">
						<ul className="list-unstyled provider-contact-info">
						<li><label className="detail-label"><FaPhoneSquare size="22px" className="icons" color="black" /></label> {provider.mobile}</li>
							<li><label className="detail-label"><FaPaperPlane size="18px" className="icons" color="black" /></label>{provider.address}</li>
							<li><label className="detail-label"><FaEnvelope size="18px" className="icons" color="black" /></label>{provider.email}</li>
						</ul>
					</div>
	
				
			</div>}
		</React.Fragment>
		); 
}

export default ProviderDetail;
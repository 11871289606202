export default class ReviewModel {
	constructor(id, user_id, user_fullname, user_profilepic, rating, comment, likes, dislikes, reply_count, created_at, liked=false, disliked=false) {
		this.id = id;
		this.user_id = user_id;
		this.user_fullname = user_fullname;
		this.profile_pic = user_profilepic;
		this.rating = rating;
		this.comment = comment;
		this.likes = likes;
		this.dislikes = dislikes;
		this.reply_count = reply_count;
		this.created_at = created_at;
		this.liked = false;
		this.disliked = false;
		this.liked = liked;
		this.disliked = disliked;
	}
}